export var validateAndGetUrlWithOrgSlug = function validateAndGetUrlWithOrgSlug(_ref) {
  var _me$organization;
  var me = _ref.me,
    orgSlug = _ref.orgSlug,
    url = _ref.url,
    fallbackUrl = _ref.fallbackUrl;
  var slug = (me === null || me === void 0 ? void 0 : (_me$organization = me.organization) === null || _me$organization === void 0 ? void 0 : _me$organization.slug) || orgSlug;
  var defaultUrl = fallbackUrl || url;
  return slug ? "/org/".concat(slug).concat(url) : defaultUrl;
};
export default validateAndGetUrlWithOrgSlug;