import { validateAndGetUrlWithOrgSlug } from "../utils";
/**
 * @description Cloned of 'useStudentUrls' but not using 'useMe' hook.
 */
export var usePureStudentUrls = function usePureStudentUrls(params) {
  var _ref = params || {},
    me = _ref.me,
    isLearnDashboardEnabledForPremium = _ref.isLearnDashboardEnabledForPremium,
    isLearnDashboardEnabledForDegree = _ref.isLearnDashboardEnabledForDegree;
  var hasAccessToLearnDashboard = (me === null || me === void 0 ? void 0 : me.isB2B) || (me === null || me === void 0 ? void 0 : me.isPremium) && isLearnDashboardEnabledForPremium || (me === null || me === void 0 ? void 0 : me.isActiveDegree) && isLearnDashboardEnabledForDegree;
  var homeUrl = '/';
  if (me) {
    homeUrl = validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/' : '/',
      fallbackUrl: hasAccessToLearnDashboard ? '/learn/' : '/home/'
    });
  }
  return {
    homeUrl: homeUrl,
    myCoursesUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/my-courses/enrolled/' : '/panel/mis-cursos/'
    }),
    myMicroDegreeUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/microdegree/' : '/panel/mis-cursos/microdegree/'
    }),
    myBootcampUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/bootcamp/' : '/panel/mis-cursos/bootcamp/'
    }),
    myAcademiesUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/academies/' : '/panel/mis-cursos/academias/'
    }),
    myTracksUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/my-courses/tracks/' : '/panel/mis-cursos/especializaciones/'
    }),
    mySavedCoursesUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/my-courses/saved/' : '/panel/mis-cursos-guardados/'
    }),
    myProjectsUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/projects/' : '/panel/mis-proyectos/'
    }),
    myDiplomasUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/certificates/' : '/panel/mis-diplomas/'
    }),
    myStudyPlanUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/studyplan/' : '/panel/mi-plan-de-estudios/'
    }),
    myClassNotesUrl: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/my-courses/notes/' : '/panel/mis-apuntes/'
    }),
    contentForYou: validateAndGetUrlWithOrgSlug({
      me: me,
      url: hasAccessToLearnDashboard ? '/learn/content-for-you/' : '/'
    })
  };
};
export default usePureStudentUrls;