import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { matchMediaQuery } from '@crehana/utils';
function useMediaQuery(options) {
  var nativeMediaQueryList = matchMediaQuery(options);
  var _useState = useState(nativeMediaQueryList.matches),
    _useState2 = _slicedToArray(_useState, 2),
    matches = _useState2[0],
    setMatches = _useState2[1];
  useEffect(function () {
    function listener(event) {
      setMatches(event.matches);
    }
    if (typeof nativeMediaQueryList.addEventListener === 'function') {
      nativeMediaQueryList.addEventListener('change', listener);
    } else if (typeof nativeMediaQueryList.addListener === 'function') {
      nativeMediaQueryList.addListener(listener);
    }
    return function () {
      if (typeof nativeMediaQueryList.removeEventListener === 'function') {
        nativeMediaQueryList.removeEventListener('change', listener);
      } else if (typeof nativeMediaQueryList.removeListener === 'function') {
        nativeMediaQueryList.removeListener(listener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    matches: matches,
    nativeMediaQueryList: nativeMediaQueryList
  };
}
export default useMediaQuery;