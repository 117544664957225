import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function getImgixUrl(baseUrl, newParams) {
  // Check if no url was provided
  if (!baseUrl) {
    return baseUrl;
  }
  var url = baseUrl;
  // Declare an empty string for query params
  var query = '';
  // Split the url on '?'
  var splitted = url.split('?');
  // Declare an empty object to store params by key
  var params = {};

  // The base url will be the first part (no params)
  // If there are any params, store them in the params Object
  var _splitted = _slicedToArray(splitted, 1);
  url = _splitted[0];
  if (splitted.length > 1) {
    splitted[1].split('&').forEach(function (param) {
      var _param$split = param.split('='),
        _param$split2 = _slicedToArray(_param$split, 2),
        key = _param$split2[0],
        value = _param$split2[1];
      if (key && value) {
        // @ts-ignore
        params[key] = value;
      }
    });
  }
  // Add or replace with provided params
  params = _objectSpread(_objectSpread(_objectSpread({}, params), newParams), {}, {
    fit: (newParams === null || newParams === void 0 ? void 0 : newParams.fit) || params.fit || 'crop'
  });
  // Go through every param (if any) to build the query params
  if (Object.keys(params).length > 0) {
    Object.keys(params).forEach(function (key) {
      query = query.concat("".concat(key, "=").concat(params[key], "&"));
    });
    // Remove last unnecessary '&'
    query = query.slice(0, query.length - 1);
  }
  // Concat the query params to the url if any
  if (query.length > 0) {
    url = "".concat(url, "?").concat(query);
  }
  // Return the url built
  return url;
}
export default getImgixUrl;